import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-phone-input-2/lib/material.css';

import { useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import PlaceIcon from '@mui/icons-material/Place';
import RemoveIcon from '@mui/icons-material/Remove';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
// import UpgradeIcon from '@mui/icons-material/Upgrade';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Skeleton,
  TextField,
} from '@mui/material';
import { clsx } from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Map, { Marker } from 'react-map-gl';
import PhoneInput from 'react-phone-input-2';
import { useFilePicker } from 'use-file-picker';
import * as yup from 'yup';

import CustomPrimaryButton from '../../components/CustomButton';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import Modal from '../../components/Modal/Modal';
import TextButton from '../../components/TextButton/index';
import { COLORS } from '../../constants';
import { useAlert } from '../../helpers/AlertProvider';
import { formatConfigInfo } from '../../helpers/interfaces/configurationInterface';
import { uploadFile } from '../../helpers/upload';
import { QUERY_CONFIGURATION_DATA, UPDATE_CONFIGURATION_DATA } from '../../queries/configuration';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';

const ConfigurationPage = () => {
  const classes = styles();
  const fontClasses = fontsStyles();
  const { t } = useTranslation();
  const mapboxKey = process.env.REACT_APP_MAPBOX_TOKEN;
  const [configData, setConfigData] = useState();
  const showAlert = useAlert();

  const { data: clientData, loading: isClientDataLoading } = useQuery(QUERY_CONFIGURATION_DATA);

  useEffect(() => {
    if (clientData) {
      setConfigData(formatConfigInfo(clientData));
    }
  }, [clientData]);

  const [saveConfiguration, { loading }] = useMutation(UPDATE_CONFIGURATION_DATA, {
    onError: () => {
      showAlert(t('configurationPage.saveError'), 'error');
    },
    onCompleted: () => {
      showAlert(t('configurationPage.saveSuccess'), 'success');
    },
  });

  const validationSchema = yup.object({
    name: yup
      .string()
      .min(1, t('validationsForm.required'))
      .required(t('validationsForm.required')),
    email: yup
      .string()
      .email(t('validationsForm.validEmail'))
      .required(t('validationsForm.required')),
    phone: yup
      .string()
      .min(1, t('validationsForm.required'))
      .required(t('validationsForm.required')),
    aboutUs: yup
      .string()
      .min(1, t('validationsForm.required'))
      .required(t('validationsForm.required')),
    termsOfService: yup.object().required(t('configurationPage.termsRequired')),
  });

  const handleChangeConfig = (param, content) => {
    switch (param) {
      case 'name':
        setConfigData((prevState) => ({
          ...prevState,
          name: content,
        }));
        break;
      case 'email':
        setConfigData((prevState) => ({
          ...prevState,
          email: content,
        }));
        break;
      case 'phone':
        setConfigData((prevState) => ({
          ...prevState,
          phone: content,
        }));
        break;
      case 'aboutUs':
        setConfigData((prevState) => ({
          ...prevState,
          aboutUs: content,
        }));
        break;
      case 'faq':
        setConfigData((prevState) => ({
          ...prevState,
          faq: content,
        }));
        break;
      case 'location':
        setConfigData((prevState) => ({
          ...prevState,
          locationAddress: content,
        }));
        break;
      case 'policies':
        setConfigData((prevState) => ({
          ...prevState,
          cancelationPolicies: content,
        }));
        break;
      case 'terms':
        setConfigData((prevState) => ({
          ...prevState,
          termsOfService: content,
        }));
        break;
      case 'refund':
        setConfigData((prevState) => ({
          ...prevState,
          reimbursements: content,
        }));
        break;
      case 'privacy':
        setConfigData((prevState) => ({
          ...prevState,
          privacyPolicy: content,
        }));
        break;
    }
  };

  const handleUpdate = () => {
    validationSchema
      .validate(configData)
      .then((valid) => {
        saveConfiguration({
          variables: {
            data: {
              name: configData.name,
              email: configData.email,
              about_us: configData.aboutUs,
              location_address: configData.locationAddress,
              frequently_asked: configData.faq,
              cancelation_policy: configData.cancelationPolicies,
              terms_of_service: configData.termsOfService.id,
              reimbursements: configData.reimbursements.id,
              privacy_policy: configData.privacyPolicy.id,
            },
          },
        });
      })
      .catch((error) => {
        showAlert(error.message, 'error');
      });
  };

  const GeneralInformation = ({ data, updateConfig }) => {
    const [tempName, setTempName] = useState(data.name);
    const [tempPhone, setTempPhone] = useState(data.phone);
    const [tempEmail, setTempEmail] = useState(data.email);
    const [tempAbout, setTempAbout] = useState(data.aboutUs);

    const updateParent = () => {
      updateConfig('name', tempName);
      updateConfig('phone', tempPhone);
      updateConfig('email', tempEmail);
      updateConfig('aboutUs', tempAbout);
      showAlert(t('configurationPage.itemModified'), 'info');
    };

    return (
      <div className={classes.configSection}>
        <h5 className={clsx(fontClasses.h5, classes.title)}>
          {t('configurationPage.titleGeneral')}
        </h5>
        <div>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <p className={clsx(fontClasses.p, fontClasses.semiBold)}>
                {t('configurationPage.titleName')}
              </p>
              <TextField
                fullWidth
                variant='outlined'
                id='name'
                name='name'
                type='text'
                value={tempName}
                onChange={(event) => setTempName(event.target.value)}
                onBlur={updateParent}
              />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <p className={clsx(fontClasses.p, fontClasses.semiBold)}>
                {t('configurationPage.phoneName')}
              </p>
              <div className={classes.phoneInput}>
                <PhoneInput
                  country={'us'}
                  inputClass={classes.phoneInput}
                  specialLabel
                  value={tempPhone}
                  onChange={(value, country, e, formattedValue) => setTempPhone(formattedValue)}
                  onBlur={updateParent}
                />
              </div>
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <p className={clsx(fontClasses.p, fontClasses.semiBold)}>
                {t('configurationPage.emailName')}
              </p>
              <TextField
                fullWidth
                variant='outlined'
                id='email'
                name='email'
                type='email'
                value={tempEmail}
                onChange={(event) => setTempEmail(event.target.value)}
                onBlur={updateParent}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <p className={clsx(fontClasses.p, fontClasses.semiBold)}>
                {t('configurationPage.AboutName')}
              </p>
              <TextField
                fullWidth
                variant='outlined'
                multiline
                rows={20}
                id='about'
                name='about'
                value={tempAbout}
                onChange={(event) => setTempAbout(event.target.value)}
                onBlur={updateParent}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

  const LocationAddress = ({ data, updateConfig }) => {
    const [longitude, setLongitude] = useState(data.longitude);
    const [latitude, setLatitude] = useState(data.latitude);
    const [interactive, setInteractive] = useState(false);

    const handleMapActive = () => {
      setInteractive(true);
    };

    const handleMapClick = (event) => {
      setLatitude(event.lngLat.lat);
      setLongitude(event.lngLat.lng);
      setInteractive(true);
    };

    const handleMapMouseLeave = () => {
      if (interactive) {
        setInteractive(false);
        updateParent();
        showAlert(t('configurationPage.locationUpdated'), 'info');
      }
    };

    const updateParent = () => {
      updateConfig('location', {
        latitude,
        longitude,
      });
    };

    return (
      <div className={classes.configSection}>
        <h5 className={clsx(fontClasses.h5, classes.title)}>
          {t('configurationPage.titleLocation')}
        </h5>
        <div className={classes.mapArea} onMouseLeave={handleMapMouseLeave}>
          {!interactive && (
            <div onClick={handleMapActive} className={classes.overlayTextbox}>
              <p className={fontClasses.h5}>{t('configurationPage.mapInteraction')}</p>
            </div>
          )}
          <Map
            mapboxAccessToken={mapboxKey}
            initialViewState={{
              longitude,
              latitude,
              zoom: 16,
            }}
            style={{ width: '100%', height: '500px' }}
            mapStyle='mapbox://styles/jeanvegad/clnfkgnl1083001qx6dbdg9r7'
            attributionControl={false}
            logoPosition='bottom-right'
            interactive={interactive}
            dragPan={interactive}
            scrollZoom={interactive}
            onClick={handleMapClick}
          >
            {interactive && (
              <Marker longitude={longitude} latitude={latitude} anchor='bottom'>
                <PlaceIcon style={{ fontSize: '60px', color: COLORS.green }} />
              </Marker>
            )}
          </Map>
        </div>
      </div>
    );
  };

  const FAQ = ({ data, updateConfig }) => {
    const [questions, setQuestions] = useState(data.faq);
    const [expanded, setExpanded] = useState(false);
    const [newQuestionModal, setNewQuestionModal] = useState(false);
    const [editQuestionSelection, setEditQuestionSelection] = useState();
    const [editQuestionIndex, setEditQuestionIndex] = useState();
    const [deleteQuestionModal, setDeteleteQuestionModal] = useState(false);
    const [removeQuestionIndex, setRemoveQuestionIndex] = useState();

    const ModalQuestionContent = ({
      handleAddUpdate,
      questionElement,
      questionIndex,
      handleChangeModalState,
    }) => {
      const [question, setQuestion] = useState(questionElement ? questionElement.question : '');
      const [answer, setAnswer] = useState(questionElement ? questionElement.answer : '');

      return (
        <div className={classes.modalContent}>
          <div className={classes.closeButton}>
            <IconButton size='large' onClick={handleChangeModalState}>
              <CloseIcon sx={{ color: COLORS.gray, fontSize: '28px' }} />
            </IconButton>
          </div>
          <p className={fontClasses.h3}>
            {questionIndex || questionIndex === 0
              ? t('configurationPage.editNewQuestion')
              : t('configurationPage.addQuestion')}
          </p>
          <div className={classes.textFieldBox}>
            <p className={clsx(fontClasses.p, fontClasses.semiBold)}>
              {t('configurationPage.question')}
            </p>
            <TextField
              id='cruiseName'
              value={question}
              onChange={(event) => {
                setQuestion(event.target.value);
              }}
              variant='outlined'
              fullWidth
            />
          </div>
          <div className={classes.textFieldBox}>
            <p className={clsx(fontClasses.p, fontClasses.semiBold)}>
              {t('configurationPage.answer')}
            </p>
            <TextField
              id='cruiseName'
              value={answer}
              multiline
              rows={4}
              onChange={(event) => {
                setAnswer(event.target.value);
              }}
              variant='outlined'
              fullWidth
            />
          </div>
          <CustomPrimaryButton
            label={t('configurationPage.buttonSave')}
            disabled={!(question?.trim() && answer?.trim())}
            onClick={() => handleAddUpdate({ question, answer }, questionIndex)}
          />
        </div>
      );
    };

    const handleChangeAccordion = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    const handleChangeModalState = () => {
      setNewQuestionModal(false);
      setEditQuestionSelection(null);
      setEditQuestionIndex(null);
    };

    const handleClickRemoveQuestion = (index) => {
      const updatedFaq = questions.filter((_, i) => i !== index);
      setQuestions(updatedFaq);
      updateConfig('faq', updatedFaq);
      setExpanded(false);
      showAlert(t('configurationPage.deleteQuestion'), 'info');
    };

    const handleAddUpdate = (element, index) => {
      const updatedFaq = questions;
      if (index || index === 0) {
        updatedFaq[index] = element;
      } else {
        updatedFaq.push(element);
      }
      updateConfig('faq', updatedFaq);
      handleChangeModalState();
      showAlert(t('configurationPage.addOrUpdateQuestion'), 'info');
    };

    return (
      <div className={classes.configSection}>
        <h5 className={clsx(fontClasses.h5, classes.title)}>{t('configurationPage.titleFAQ')}</h5>
        <div className={classes.faqWrapper} id={'frequently-asked'}>
          {questions.map((item, index) => (
            <Accordion
              expanded={expanded === `faqPanel${index}`}
              disableGutters
              elevation={0}
              onChange={handleChangeAccordion(`faqPanel${index}`)}
              key={index}
            >
              <AccordionSummary
                expandIcon={
                  expanded === `faqPanel${index}` ? (
                    <RemoveIcon style={{ color: COLORS.green }} />
                  ) : (
                    <AddIcon style={{ color: COLORS.black }} />
                  )
                }
                aria-controls={`faqPanel${index}-content`}
                id={`faqPanel${index}-header`}
              >
                <p
                  className={clsx(fontClasses.p, classes.questionTitle)}
                  style={{
                    color: expanded === `faqPanel${index}` ? COLORS.green : COLORS.black,
                  }}
                >
                  {item.question}
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className={fontClasses.p}>{item.answer}</p>
                <div className={classes.faqQuestion}>
                  <CustomPrimaryButton
                    secondary
                    label={t('configurationPage.removeQuestion')}
                    icon={<DeleteOutlineIcon sx={{ color: COLORS.green }} />}
                    onClick={() => {
                      setRemoveQuestionIndex(index);
                      setDeteleteQuestionModal(true);
                    }}
                  />
                  <CustomPrimaryButton
                    label={t('configurationPage.editQuestion')}
                    icon={<EditOutlinedIcon sx={{ color: COLORS.white }} />}
                    onClick={() => {
                      setEditQuestionSelection(item);
                      setEditQuestionIndex(index);
                      setNewQuestionModal(true);
                    }}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
          <Modal
            pModalOpen={newQuestionModal}
            pHandleClose={handleChangeModalState}
            pModalContent={
              <ModalQuestionContent
                questionElement={editQuestionSelection}
                questionIndex={editQuestionIndex}
                handleAddUpdate={handleAddUpdate}
                handleChangeModalState={handleChangeModalState}
              />
            }
          />
        </div>
        <div className={classes.actionButtonsBox}>
          <TextButton
            label={t('configurationPage.addQuestion')}
            icon={<AddIcon />}
            onClick={() => setNewQuestionModal(true)}
          />
        </div>
        <DeleteModal
          pMessageText={t('deleteModal.faQuestion')}
          pModalOpen={deleteQuestionModal}
          pHandleClose={() => setDeteleteQuestionModal(false)}
          deleteAction={() => handleClickRemoveQuestion(removeQuestionIndex)}
        />
      </div>
    );
  };

  const CancelationPolicy = ({ data, updateConfig }) => {
    const [expandedPanels, setExpandedPanels] = useState({});
    const [tempPolicies, setTempPolicies] = useState(data);
    const [deleteCluseModal, setDeteleteClauseModal] = useState(false);
    const [deleteCluseItemModal, setDeteleteClauseItemModal] = useState(false);
    const [deletePosition, setDeletePosition] = useState();
    const [deletePositionItem, setDeletePositionItem] = useState();

    const handleChangeExpanded = (index) => {
      setExpandedPanels((prev) => ({
        ...prev,
        [index]: !prev[index], // Toggle the state of the clicked accordion
      }));
    };

    const handleClauseTitleChange = (position, newTitle) => {
      const updatedData = [...tempPolicies];
      updatedData[position].title = newTitle;
      setTempPolicies(updatedData);
    };

    const handleItemTextChange = (position, element, newText) => {
      const updatedData = [...tempPolicies];
      updatedData[position].description[element] = newText;
      setTempPolicies(updatedData);
    };

    const handleRemoveClauseTitle = (position) => {
      const newPolicies = tempPolicies.filter((_, index) => index !== position);
      updateConfig('policies', newPolicies);
      showAlert(t('configurationPage.deleteCluase'), 'info');
    };

    const handleRemoveItemText = (position, element) => {
      const newPolicies = tempPolicies;
      const tempElements = tempPolicies[position].description;
      newPolicies[position].description = tempElements.filter((_, index) => index !== element);
      updateConfig('policies', newPolicies);
      showAlert(t('configurationPage.deleteCluaseItem'), 'info');
    };

    const handleAddClauseTitle = () => {
      const newPolicies = tempPolicies;
      newPolicies.push({ title: t('configurationPage.newClause'), description: [] });
      updateConfig('policies', newPolicies);
    };

    const handleAddItemText = (position) => {
      const newPolicies = tempPolicies;
      newPolicies[position].description.push(t('configurationPage.newItemClause'));
      updateConfig('policies', newPolicies);
    };

    const updateParent = () => {
      updateConfig('policies', tempPolicies);
      showAlert(t('configurationPage.itemModified'), 'info');
    };

    return (
      <div className={classes.configSection}>
        <h5 className={clsx(fontClasses.h5, classes.title)}>{t('configurationPage.titleCP')}</h5>
        <div className={classes.accordionBox}>
          {tempPolicies.map((item, index) => (
            <Accordion
              key={`clause-${index}`}
              disableGutters
              elevation={0}
              slotProps={{ heading: { component: 'h4' } }}
              sx={{ border: 'none', width: '100%' }}
              expanded={!expandedPanels[index]}
            >
              <div className={classes.accordionHeader}>
                <div>
                  <IconButton onClick={() => handleChangeExpanded(index)}>
                    {!expandedPanels[index] ? (
                      <KeyboardArrowDownOutlinedIcon />
                    ) : (
                      <KeyboardArrowUpOutlinedIcon />
                    )}
                  </IconButton>
                </div>
                <TextField
                  value={item.title}
                  onChange={(e) => handleClauseTitleChange(index, e.target.value)}
                  onBlur={updateParent}
                  fullWidth
                  InputProps={{
                    endAdornment: item.description.length === 0 && (
                      <InputAdornment position='start'>
                        <IconButton
                          aria-label='search'
                          edge='end'
                          onClick={() => {
                            setDeletePosition(index);
                            setDeteleteClauseModal(true);
                            // handleRemoveClauseTitle(index);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <AccordionDetails sx={{ padding: 0 }}>
                <div className={classes.accordionSummary}>
                  {item.description.map((descriptionItem, descriptionIndex) => (
                    <div className={classes.itemClause} key={`clause-item-${descriptionIndex}`}>
                      <FiberManualRecordIcon sx={{ fontSize: 12 }} />
                      <TextField
                        fullWidth
                        onChange={(e) =>
                          handleItemTextChange(index, descriptionIndex, e.target.value)
                        }
                        onBlur={updateParent}
                        value={descriptionItem}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='start'>
                              <IconButton
                                aria-label='search'
                                edge='end'
                                onClick={() => {
                                  setDeletePosition(index);
                                  setDeletePositionItem(descriptionIndex);
                                  setDeteleteClauseItemModal(true);
                                }}
                              >
                                <DeleteOutlineIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  ))}
                  <TextButton
                    icon={<AddIcon sx={{ color: COLORS.green, fontSize: 20 }} />}
                    label={t('configurationPage.addNewItem')}
                    onClick={() => handleAddItemText(index)}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
          <div style={{ paddingLeft: '10px' }}>
            <TextButton
              icon={<AddIcon sx={{ color: COLORS.green, fontSize: 20 }} />}
              label={t('configurationPage.addNewClause')}
              onClick={handleAddClauseTitle}
            />
          </div>
        </div>
        <DeleteModal
          pMessageText={t('deleteModal.clause')}
          pModalOpen={deleteCluseModal}
          pHandleClose={() => setDeteleteClauseModal(false)}
          deleteAction={() => handleRemoveClauseTitle(deletePosition)}
        />
        <DeleteModal
          pMessageText={t('deleteModal.clauseItem')}
          pModalOpen={deleteCluseItemModal}
          pHandleClose={() => setDeteleteClauseItemModal(false)}
          deleteAction={() => handleRemoveItemText(deletePosition, deletePositionItem)}
        />
      </div>
    );
  };

  const Documents = ({ data, updateConfig }) => {
    const {
      openFilePicker: openTerms,
      filesContent: termsContent,
      clear: clearTerms,
    } = useFilePicker({
      accept: '.pdf',
      multiple: false,
      readAs: 'DataURL',
    });

    const {
      openFilePicker: openPrivacy,
      filesContent: privacyContent,
      clear: clearPrivacy,
    } = useFilePicker({
      accept: '.pdf',
      multiple: false,
      readAs: 'DataURL',
    });

    const {
      openFilePicker: openRefund,
      filesContent: RefundContent,
      clear: clearRefund,
    } = useFilePicker({
      accept: '.pdf',
      multiple: false,
      readAs: 'DataURL',
    });

    const [isTermFile, setIsTermFile] = useState();
    const [loadingTerms, setLoadingTerms] = useState(false);
    const handlerClearTerms = () => {
      clearTerms();
      updateConfig('terms', null);
    };
    const [isPrivacyFile, setIsPrivacyFile] = useState();
    const [loadingPrivacy, setLoadingPrivacy] = useState(false);
    const handlerClearPrivacy = () => {
      clearPrivacy();
      updateConfig('privacy', null);
    };
    const [isRefundFile, setIsRefundFile] = useState();
    const [loadingRefund, setLoadingRefund] = useState(false);
    const handlerClearRefund = () => {
      clearRefund();
      updateConfig('refund', null);
    };

    const uploadDocument = async (fileContent, fileName) => {
      // Convert the Base64 string (DataURL) back to a Blob/File object
      const byteString = atob(fileContent.split(',')[1]); // Extract Base64 data
      const mimeType = fileContent.split(',')[0].match(/:(.*?);/)[1];
      const arrayBuffer = new Uint8Array(byteString.length);

      for (let i = 0; i < byteString.length; i++) {
        arrayBuffer[i] = byteString.charCodeAt(i);
      }

      const file = new File([arrayBuffer], fileName, { type: mimeType });
      return await uploadFile(file);
    };

    useEffect(() => {
      if (termsContent.length > 0) {
        const { content, name } = termsContent[0];
        setLoadingTerms(true);
        uploadDocument(content, name)
          .then((response) => {
            updateConfig('terms', {
              name: response[0].name,
              url: response[0].url,
              id: response[0].id,
            });
            setLoadingTerms(false);
            showAlert(t('configurationPage.uploadSuccess'), 'info');
          })
          .catch(() => {
            setLoadingTerms(false);
            setIsTermFile(null);
            updateConfig('terms', {
              name: null,
              url: null,
              id: null,
            });
            showAlert(t('configurationPage.uploadError'), 'error');
          });
      }
    }, [termsContent]);

    useEffect(() => {
      if (privacyContent.length > 0) {
        const { content, name } = privacyContent[0];
        setLoadingPrivacy(true);
        uploadDocument(content, name)
          .then((response) => {
            updateConfig('privacy', {
              name: response[0].name,
              url: response[0].url,
              id: response[0].id,
            });
            setLoadingPrivacy(false);
            showAlert(t('configurationPage.uploadSuccess'), 'info');
          })
          .catch(() => {
            setLoadingPrivacy(false);
            setIsPrivacyFile(null);
            updateConfig('privacy', {
              name: null,
              url: null,
              id: null,
            });
            showAlert(t('configurationPage.uploadError'), 'error');
          });
      }
    }, [privacyContent]);

    useEffect(() => {
      if (RefundContent.length > 0) {
        const { content, name } = RefundContent[0];
        setLoadingRefund(true);
        uploadDocument(content, name)
          .then((response) => {
            updateConfig('refund', {
              name: response[0].name,
              url: response[0].url,
              id: response[0].id,
            });
            setLoadingRefund(false);
            showAlert(t('configurationPage.uploadSuccess'), 'info');
          })
          .catch(() => {
            setLoadingRefund(false);
            setIsRefundFile(null);
            updateConfig('refund', {
              name: null,
              url: null,
              id: null,
            });
            showAlert(t('configurationPage.uploadError'), 'error');
          });
      }
    }, [RefundContent]);

    useEffect(() => {
      if (data?.termsOfService?.name) {
        setIsTermFile({
          name: configData.termsOfService.name,
          url: configData.termsOfService.url,
          id: configData.termsOfService.id,
          online: true,
        });
      }
      if (data?.reimbursements?.name) {
        setIsRefundFile({
          name: configData.reimbursements.name,
          url: configData.reimbursements.url,
          id: configData.reimbursements.id,
          online: true,
        });
      }
      if (data?.privacyPolicy?.name) {
        setIsPrivacyFile({
          name: configData.privacyPolicy.name,
          url: configData.privacyPolicy.url,
          id: configData.privacyPolicy.id,
          online: true,
        });
      }
    }, [configData]);

    return (
      <div className={classes.configSection}>
        <h5 className={clsx(fontClasses.h5, classes.title)}>
          {t('configurationPage.titleDocument')}
        </h5>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
            <h5 className={clsx(fontClasses.h5, classes.titleDocument)}>
              {t('configurationPage.titleTerms')}
            </h5>
            <div className={classes.documentSection}>
              {loadingTerms ? (
                <div className={classes.documentStructure}>
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {isTermFile ? (
                    <div className={classes.documentStructure}>
                      <div>
                        <p
                          className={clsx(
                            fontClasses.p,
                            fontClasses.smallText,
                            classes.documentName,
                          )}
                        >
                          {isTermFile.name}
                        </p>
                      </div>
                      <div>
                        {isTermFile.online && (
                          <IconButton
                            size='small'
                            onClick={() => window.open(isTermFile.url, '_blank')}
                          >
                            <InsertDriveFileOutlinedIcon
                              sx={{ color: COLORS.gray, fontSize: 20 }}
                            />
                          </IconButton>
                        )}
                        <IconButton size='small' onClick={openTerms} color='primary'>
                          <FileUploadOutlinedIcon sx={{ color: COLORS.gray, fontSize: 20 }} />
                        </IconButton>
                        <IconButton size='small' onClick={handlerClearTerms} color='primary'>
                          <DeleteOutlineIcon sx={{ color: COLORS.gray, fontSize: 20 }} />
                        </IconButton>
                      </div>
                    </div>
                  ) : (
                    <div className={classes.selectDocument} onClick={openTerms}>
                      <AddIcon sx={{ color: COLORS.gray, fontSize: 20 }} />
                      <p className={clsx(fontClasses.p, fontClasses.smallText)}>Add Document</p>
                    </div>
                  )}
                </>
              )}
            </div>
          </Grid>
          <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
            <h5 className={clsx(fontClasses.h5, classes.titleDocument)}>
              {t('configurationPage.titleReimbursements')}
            </h5>
            <div className={classes.documentSection}>
              {loadingRefund ? (
                <div className={classes.documentStructure}>
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {isRefundFile ? (
                    <div className={classes.documentStructure}>
                      <div>
                        <p
                          className={clsx(
                            fontClasses.p,
                            fontClasses.smallText,
                            classes.documentName,
                          )}
                        >
                          {isRefundFile.name}
                        </p>
                      </div>
                      <div>
                        {isRefundFile.online && (
                          <IconButton
                            size='small'
                            onClick={() => window.open(isRefundFile.url, '_blank')}
                          >
                            <InsertDriveFileOutlinedIcon
                              sx={{ color: COLORS.gray, fontSize: 20 }}
                            />
                          </IconButton>
                        )}
                        <IconButton size='small' onClick={openRefund} color='primary'>
                          <FileUploadOutlinedIcon sx={{ color: COLORS.gray, fontSize: 20 }} />
                        </IconButton>
                        <IconButton size='small' onClick={handlerClearRefund} color='primary'>
                          <DeleteOutlineIcon sx={{ color: COLORS.gray, fontSize: 20 }} />
                        </IconButton>
                      </div>
                    </div>
                  ) : (
                    <div className={classes.selectDocument} onClick={openRefund}>
                      <AddIcon sx={{ color: COLORS.gray, fontSize: 20 }} />
                      <p className={clsx(fontClasses.p, fontClasses.smallText)}>Add Document</p>
                    </div>
                  )}
                </>
              )}
            </div>
          </Grid>
          <Grid item sm={12} xs={12} md={12} lg={4} xl={4}>
            <h5 className={clsx(fontClasses.p, fontClasses.semiBold)}>
              {t('configurationPage.titlePrivacyPolicy')}
            </h5>
            <div className={classes.documentSection}>
              {loadingPrivacy ? (
                <div className={classes.documentStructure}>
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {isPrivacyFile ? (
                    <div className={classes.documentStructure}>
                      <div
                        style={{
                          padding: '5px',
                        }}
                      >
                        <p
                          className={clsx(
                            fontClasses.p,
                            fontClasses.smallText,
                            classes.documentName,
                          )}
                        >
                          {isPrivacyFile.name}
                        </p>
                      </div>
                      <div>
                        {isPrivacyFile.online && (
                          <IconButton
                            size='small'
                            onClick={() => window.open(isPrivacyFile.url, '_blank')}
                          >
                            <InsertDriveFileOutlinedIcon
                              sx={{ color: COLORS.gray, fontSize: 20 }}
                            />
                          </IconButton>
                        )}
                        <IconButton size='small' onClick={openPrivacy} color='primary'>
                          <FileUploadOutlinedIcon sx={{ color: COLORS.gray, fontSize: 20 }} />
                        </IconButton>
                        <IconButton size='small' onClick={handlerClearPrivacy} color='primary'>
                          <DeleteOutlineIcon sx={{ color: COLORS.gray, fontSize: 20 }} />
                        </IconButton>
                      </div>
                    </div>
                  ) : (
                    <div className={classes.selectDocument} onClick={openPrivacy}>
                      <AddIcon sx={{ color: COLORS.gray, fontSize: 20 }} />
                      <p className={clsx(fontClasses.p, fontClasses.smallText)}>Add Document</p>
                    </div>
                  )}
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <div className={classes.mainWrapper}>
      <Grid container>
        <Grid item sm={12} xs={12} md={12} lg={12} xl={10}>
          <div className={classes.section}>
            <div className={classes.mainTitle}>
              <h3 className={fontClasses.h3}>{t('configurationPage.title')}</h3>
              <CustomPrimaryButton
                icon={<SaveOutlinedIcon />}
                tertiary
                label={t('configurationPage.buttonSave')}
                submit
                onClick={handleUpdate}
                disabled={loading}
              />
            </div>
            {isClientDataLoading && (
              <>
                <Skeleton
                  variant='rectangular'
                  width='100%'
                  height='300px'
                  sx={{
                    borderRadius: '10px',
                    width: '100%',
                    marginBottom: '5px',
                  }}
                />
                <Skeleton
                  variant='rectangular'
                  width='100%'
                  height='300px'
                  sx={{
                    borderRadius: '10px',
                    width: '100%',
                    marginBottom: '5px',
                  }}
                />
              </>
            )}
            {!isClientDataLoading && configData && (
              <>
                <GeneralInformation data={configData} updateConfig={handleChangeConfig} />
                <LocationAddress
                  data={configData.locationAddress}
                  updateConfig={handleChangeConfig}
                />
                <FAQ data={configData} updateConfig={handleChangeConfig} />
                <CancelationPolicy
                  data={configData.cancelationPolicies}
                  updateConfig={handleChangeConfig}
                />
                <Documents data={configData} updateConfig={handleChangeConfig} />
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ConfigurationPage;
