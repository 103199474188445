export function formatFrequentlyAsked(response, t) {
  return response.clientParam.data.attributes.frequently_asked.map((item) => ({
    answer: item.answer,
    question: item.question,
  }));
}

export function formatCancelationPolicy(response, t) {
  return response.clientParam.data.attributes.cancelation_policy.map((item) => ({
    title: item.title,
    description: item.description,
  }));
}

export function formatGeneralInfo(response, t) {
  return response.clientParam.data.attributes.map((item) => ({
    answer: item.title,
    question: item.description,
  }));
}

export function formatConfigInfo(response) {
  return {
    name: response.clientParam.data.attributes.name,
    aboutUs: response.clientParam.data.attributes.about_us,
    phone: response.clientParam.data.attributes.phone,
    email: response.clientParam.data.attributes.email,
    locationAddress: response.clientParam.data.attributes.location_address,
    faq: response.clientParam.data.attributes.frequently_asked,
    cancelationPolicies: response.clientParam.data.attributes.cancelation_policy,
    termsOfService: {
      id: response.clientParam.data.attributes.terms_of_service.data?.id,
      name: response.clientParam.data.attributes.terms_of_service.data?.attributes.name,
      url: response.clientParam.data.attributes.terms_of_service.data?.attributes.url,
    },
    reimbursements: {
      id: response.clientParam.data.attributes.reimbursements.data?.id,
      name: response.clientParam.data.attributes.reimbursements.data?.attributes.name,
      url: response.clientParam.data.attributes.reimbursements.data?.attributes.url,
    },
    privacyPolicy: {
      id: response.clientParam.data.attributes.privacy_policy.data?.id,
      name: response.clientParam.data.attributes.privacy_policy.data?.attributes.name,
      url: response.clientParam.data.attributes.privacy_policy.data?.attributes.url,
    },
  };
}
