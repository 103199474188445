import { useMutation } from '@apollo/client';
import { Alert, AlertTitle, CircularProgress } from '@mui/material';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import CustomPrimaryButton from '../../components/CustomButton';
import { COLORS, URL_IMAGES } from '../../constants';
import FORGET_PASSWORD_FORM from '../../queries/forgetPasspord';
import fontStyles from '../../styles/fontStyles';
import styles from './styles';

const RecoveryPage = () => {
  const classes = styles();
  const fontClasses = fontStyles();
  const { t } = useTranslation();

  const [forgetPasswordForm, { loading, error }] = useMutation(FORGET_PASSWORD_FORM);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const validationSchema = yup.object({
    email: yup
      .string()
      .matches(emailRegex, t('validationsForm.validEmail'))
      .required(t('validationsForm.complete')),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      await forgetPasswordForm({
        variables: { ...values },
      });
      setAlertType('success');
      setAlertMessage(t('passwordPage.successMessage'));
      setShowAlert(true);
      formik.resetForm();
    },
  });

  const handleMessage = (event) => {
    setShowAlert(false);
  };

  useEffect(() => {
    if (error) {
      setAlertType('error');
      setAlertMessage(t('validationsForm.serverError'));
      setShowAlert(true);
    }
  }, [error]);

  return (
    <div className={classes.mainWrapper}>
      <div className={classes.loginBox}>
        <div className={classes.logoTitle}>
          <Link to='/' className={classes.logoBox}>
            <img
              className={classes.logo}
              src={URL_IMAGES.URL_LOGO_CARIBBEAN_ADVENTURES}
              alt='logo'
            />
          </Link>
          <h5 className={clsx(fontClasses.h5, classes.title)}>{t('passwordPage.title')}</h5>
        </div>
        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
          <div className={classes.formBox}>
            <div className={classes.inputTextBox}>
              <p className={clsx(fontClasses.p, classes.titleInput)}>{t('passwordPage.email')}</p>
              <TextField
                fullWidth
                variant='outlined'
                id='email'
                name='email'
                value={formik.values.email}
                onChange={formik.handleChange}
              />
            </div>
            {Object.keys(formik.errors).length > 0 && formik.submitCount > 0 && (
              <div className={classes.alertError}>
                <Alert severity='error'>{formik.errors.email}</Alert>
              </div>
            )}
            {showAlert && (
              <div className={classes.alertError}>
                <Alert onClose={handleMessage} severity={alertType} sx={{ color: COLORS.gray }}>
                  {alertType === 'success' && (
                    <AlertTitle>{t('passwordPage.successTitle')}</AlertTitle>
                  )}
                  {alertMessage}
                </Alert>
              </div>
            )}
            <div className={classes.buttonBox}>
              {loading && <CircularProgress color='primary' />}
              <CustomPrimaryButton
                fullWidth
                tertiary
                label={t('passwordPage.submitButton')}
                disabled={loading}
                loading
                submit
              />
              <Link to='/' className={classes.linkForget} underline='none'>
                <p className={clsx(fontClasses.p, classes.forgetLink)}>
                  {t('passwordPage.loginLink')}
                </p>
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RecoveryPage;
