import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS } from '../../constants';
import breakpoints from '../../theme/breakpoints';
const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  mainWrapper: {
    overflowY: 'hidden',
    backgroundColor: 'white',
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url('https://res.cloudinary.com/sealabs/image/upload/caribbeanadventures.cr/bottom_leaves_eppfhh.svg')`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '100% 115%',
    position: 'relative',
    [themeBreakpoints.up('xl')]: {
      backgroundPosition: '100% 125%',
    },
    [themeBreakpoints.down('md')]: {
      backgroundPosition: '100% 100%',
      alignItems: 'center',
    },
    [themeBreakpoints.down('sm')]: {
      backgroundPosition: '100% 100%',
      alignItems: 'flex-start',
    },
  },
  loginBox: {
    backgroundColor: 'white',
    width: '500px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.2)',
    padding: '50px',
    boxSizing: 'border-box',
    borderRadius: '10px',
    position: 'absolute',
    [themeBreakpoints.down('sm')]: {
      display: 'flex',
      width: '90%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: 'none',
      margin: '50px 0 0 0',
      padding: '0',
    },
  },
  logoBox: {
    width: '60%',
    paddingTop: '15px',
    [themeBreakpoints.down('sm')]: {
      width: '90%',
    },
  },
  logoTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    paddingTop: '15px',
    paddingBottom: '15px',
    color: COLORS.gray,
    [themeBreakpoints.down('sm')]: {
      paddingBottom: '35px',
    },
  },
  forgetLink: {
    textDecoration: 'none',
  },
  logo: {
    width: '100%',
  },
  formBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  alertError: {
    width: '100%',
  },
  inputTextBox: {
    width: '100%',
  },
  titleInput: {
    fontWeight: 600,
    color: COLORS.gray,
    marginBottom: '5px',
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
  },
  linkForget: {
    cursor: 'pointer',
    textDecoration: 'none',
    '& :hover': {
      color: COLORS.orange,
    },
  },
  plantsImage: {
    overflowY: 'hidden',
    width: '100%',
    height: '100%',
  },
}));

export default styles;
