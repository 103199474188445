import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  mainWrapper: {
    height: '100vh',
    backgroundColor: COLORS.grayBackground,
  },
  section: {
    height: '100vh',
    overflow: 'scroll',
    boxSizing: 'border-box',
    padding: '25px 25px',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    [themeBreakpoints.down('sm')]: {
      height: 'calc(100vh - 75px)',
      padding: '25px 25px',
    },
    '&::-webkit-scrollbar': {
      height: '3px',
      width: '3px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '5px',
      backgroundColor: COLORS.grayBackground,
    },
    '&::-webkit-scrollbar-track:hover': {
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar-track:active': {
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: COLORS.scrollbar,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: COLORS.scrollbar,
    },
    '&::-webkit-scrollbar-thumb:active': {
      backgroundColor: COLORS.scrollbar,
    },
  },
  mainTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 10,
  },
  messageList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  emptyList: {
    height: '100%',
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: '10px',
    justifyContent: 'center',
  },
  emptyMessageBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
  },
  paginationBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default styles;
