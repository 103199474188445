import { useMutation, useQuery } from '@apollo/client';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Grid, Pagination, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmationModal from '../../components/ConfirmationModal';
import ContactCard from '../../components/ContactCard';
import { COLORS } from '../../constants';
import { useAlert } from '../../helpers/AlertProvider';
import { formatContact } from '../../helpers/interfaces/contactInterface';
import { DELETE_CONTACT_FORM, GET_CONTACT_FORMS } from '../../queries/contactForm';
import fontsStyles from '../../styles/fontStyles';
import styles from './styles';

const ContactFormPage = () => {
  const classes = styles();
  const fontClasses = fontsStyles();
  const { t } = useTranslation();
  const [contactForms, setContactForms] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const showAlert = useAlert();
  const [deleteID, setDeleteID] = useState();
  const [reFetchForms, setReFetchForms] = useState(false);
  const [deleteContact] = useMutation(DELETE_CONTACT_FORM, {
    variables: {
      deleteContactFormId: deleteID,
    },
    onCompleted: () => {
      setReFetchForms(true);
      showAlert(t('contactPage.succesDelete'), 'success');
    },
    onError: () => {
      showAlert(t('contactPage.errorDelete'), 'error');
    },
  });

  const {
    data: contactFormData,
    loading,
    refetch,
  } = useQuery(GET_CONTACT_FORMS, {
    variables: {
      pagination: {
        page,
        pageSize: 5,
      },
    },
  });

  const handleCloseModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDeleteContact = () => {
    deleteContact();
    setOpenDeleteModal(false);
  };

  useEffect(() => {
    if (reFetchForms) {
      refetch();
    }
  }, [reFetchForms]);

  useEffect(() => {
    if (contactFormData) {
      setContactForms(formatContact(contactFormData));
      setPageCount(contactFormData.contactForms.meta.pagination.pageCount);
    }
  }, [contactFormData]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <div className={classes.mainWrapper}>
      <Grid container>
        <Grid item sm={12} xs={12} md={12} lg={12} xl={10}>
          <div className={classes.section}>
            <div className={classes.mainTitle}>
              <h2 className={fontClasses.h3}>{t('contactPage.contactForm')}</h2>
            </div>
            {loading && (
              <div className={classes.messageList}>
                <Skeleton
                  variant='rectangular'
                  height={'200px'}
                  sx={{
                    borderRadius: '10px',
                    width: '100%',
                  }}
                />
                <Skeleton
                  variant='rectangular'
                  height={'200px'}
                  sx={{
                    borderRadius: '10px',
                    width: '100%',
                  }}
                />
              </div>
            )}
            {contactForms.length > 0 && (
              <div className={classes.messageList}>
                {contactForms.map((item, index) => (
                  <ContactCard
                    key={'cardForm-' + index}
                    id={item.id}
                    name={item.name}
                    email={item.email}
                    date={item.date}
                    subject={item.subject}
                    message={item.message}
                    setDeleteID={setDeleteID}
                    setOpenDeleteModal={setOpenDeleteModal}
                  />
                ))}
                {pageCount > 1 && (
                  <div className={classes.paginationBox}>
                    <Pagination
                      page={page}
                      count={pageCount}
                      color='primary'
                      onChange={handleChangePage}
                    />
                  </div>
                )}
              </div>
            )}
            {!loading && contactForms.length === 0 && (
              <div className={classes.emptyList}>
                <div className={classes.emptyMessageBox}>
                  <ErrorOutlineOutlinedIcon sx={{ fontSize: '50px', color: COLORS.gray }} />
                  <p className={fontClasses.p}>{t('contactPage.emptyMessage')}</p>
                </div>
              </div>
            )}
          </div>
          <ConfirmationModal
            open={openDeleteModal}
            title={t('contactPage.deleteContactTitle')}
            text={t('contactPage.deleteContactText')}
            labelApproveButton={t('contactPage.deleteContactApprove')}
            labelRejectButton={t('contactPage.deleteContactRject')}
            handlerApproveButton={handleDeleteContact}
            handlerRejectButton={handleCloseModal}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactFormPage;
