import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  mainCard: {
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: '20px',
    cursor: 'pointer',
    [themeBreakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  contentSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  fromSection: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  fromSectionContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
  },
  emailText: {
    fontSize: '14px',
    color: COLORS.green,
  },
  dateText: {
    fontSize: '14px',
  },
  subjectText: {
    fontWeight: 600,
  },
  buttonsSection: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
    [themeBreakpoints.down('md')]: {
      gap: 10,
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  buttonsSectionAux: {
    display: 'flex',
  },
}));

export default styles;
