import './styles/index.css';
import './i18n';

import { createRoot } from 'react-dom/client';

import App from './App';
import { AppWrapper } from './helpers/AlertProvider';
import AuthProvider from './helpers/AuthenticationProvider';

const domNode = document.getElementById('root');
const root = createRoot(domNode);
root.render(
  <AppWrapper>
    <AuthProvider>
      <App />
    </AuthProvider>
  </AppWrapper>,
);
