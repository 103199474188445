import { Dialog, SwipeableDrawer } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import fontsStyles from '../../styles/fontStyles';
import breakpoints from '../../theme/breakpoints';
import CustomPrimaryButton from '../CustomButton';
import styles from './styles';

const DeleteModal = ({ pModalOpen, pHandleClose, pMessageText, deleteAction }) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.values.md}px)` });
  const classes = styles();
  const fontClasses = fontsStyles();
  const { t } = useTranslation();

  const Content = () => {
    return (
      <div className={classes.modalContent}>
        <div className={classes.textFieldBox}>
          <p className={fontClasses.h4}>
            {t('deleteModal.message')} {pMessageText}?
          </p>
        </div>
        <div className={classes.modalButtonsContent}>
          <CustomPrimaryButton label={t('deleteModal.cancel')} secondary onClick={pHandleClose} />
          <CustomPrimaryButton label={t('deleteModal.delete')} onClick={deleteAction} />
        </div>
      </div>
    );
  };

  return (
    <>
      {!isMobile ? (
        <Dialog
          open={pModalOpen}
          sx={{ zIndex: 6000 }}
          fullWidth
          maxWidth='sm'
          onClose={pHandleClose}
        >
          <Content />
        </Dialog>
      ) : (
        <SwipeableDrawer
          anchor={'bottom'}
          open={pModalOpen}
          onClose={pHandleClose}
          PaperProps={{ elevation: 0, style: { backgroundColor: 'transparent' } }}
          sx={{ zIndex: 6000 }}
        >
          <Content />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default DeleteModal;
