import { jwtDecode } from 'jwt-decode';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAlert } from '../helpers/AlertProvider';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const showAlert = useAlert();
  const { t } = useTranslation();
  const [token, setToken_] = useState(localStorage.getItem('cad-jwt-token'));
  const [userId, setUserId_] = useState(localStorage.getItem('cad-user-id'));

  // Function to set the authentication token
  const setToken = (newToken) => {
    setToken_(newToken);
  };

  // Function to set the authentication token
  const clearToken = () => {
    setToken_(undefined);
  };

  const setUserId = (newId) => {
    setUserId_(newId);
  };

  const clearUserId = () => {
    setUserId_(undefined);
  };

  const logoutAuth = () => {
    clearToken();
    clearUserId();
    showAlert(t('loginPage.sesionExpired'), 'info');
  };

  useEffect(() => {
    if (token) {
      // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      localStorage.setItem('cad-jwt-token', token);
    } else {
      // delete axios.defaults.headers.common["Authorization"];
      localStorage.removeItem('cad-jwt-token');
    }
  }, [token]);

  useEffect(() => {
    if (userId) {
      localStorage.setItem('cad-user-id', userId);
    } else {
      localStorage.removeItem('cad-user-id');
    }
  }, [userId]);

  // Check if the token is expired
  const isTokenExpired = (jwt) => {
    if (!jwt) return true;
    try {
      const decoded = jwtDecode(jwt);
      console.log('decoded', decoded);
      console.log(decoded.exp, '<', Date.now());
      return decoded.exp * 1000 < Date.now();
    } catch (error) {
      return true;
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (token && isTokenExpired(token)) {
        logoutAuth();
      }
    }, 1800000); // Check every 30 minutes

    return () => clearInterval(interval);
  }, [token]);

  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      token,
      setToken,
      isTokenExpired,
      userId,
      setUserId,
      logoutAuth,
    }),
    [token, userId],
  );

  // Provide the authentication context to the children components
  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

// Custom hook to easily access the authentication context
export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
