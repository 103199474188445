import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  mainWrapper: {
    height: '100vh',
    backgroundColor: COLORS.grayBackground,
  },
  section: {
    height: '100vh',
    overflow: 'scroll',
    boxSizing: 'border-box',
    padding: '25px 25px',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    [themeBreakpoints.down('sm')]: {
      height: 'calc(100vh - 75px)',
      padding: '25px 25px',
    },
    '&::-webkit-scrollbar': {
      height: '3px',
      width: '3px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '5px',
      backgroundColor: COLORS.grayBackground,
    },
    '&::-webkit-scrollbar-track:hover': {
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar-track:active': {
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: COLORS.scrollbar,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: COLORS.scrollbar,
    },
    '&::-webkit-scrollbar-thumb:active': {
      backgroundColor: COLORS.scrollbar,
    },
  },
  mainTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 10,
  },
  configSection: {
    backgroundColor: COLORS.white,
    boxSizing: 'border-box',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    borderRadius: '10px',
    [themeBreakpoints.down('sm')]: {
      padding: '25px 25px',
    },
  },
  phoneInput: {
    '& .react-tel-input .form-control': {
      width: '100%',
      borderColor: COLORS.black,
      borderRadius: '10px',
      borderWidth: '1px',
      boxSizing: 'border-box',
      fontFamily: ['Poppins'].join(','),
      fontSize: '18px',
      padding: '15px 50px',
    },
    '& .react-tel-input .form-control:focus': {
      width: '100%',
      borderColor: COLORS.green,
      borderRadius: '10px',
      outline: `1px solid ${COLORS.green}`,
      fontFamily: ['Poppins'].join(','),
      fontSize: '18px',
    },
  },
  mapArea: {
    position: 'relative',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  overlayTextbox: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(236, 246, 231, 0.7)', // Fondo semitransparente
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  title: {
    fontSize: 18,
    color: COLORS.orange,
  },
  titleDocument: {
    fontSize: 16,
    color: COLORS.gray,
  },
  faqWrapper: {
    width: '100%',
  },
  questionTitle: {
    fontSize: '16px',
    fontWeight: 600,
  },
  faqQuestion: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
    padding: '10px 0 10px 0',
  },
  documentSection: {
    border: 'solid 1px #CACACA',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    height: '100px',
  },
  selectDocument: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    height: '100px',
    '&:hover': {
      transform: 'scale(1.05)',
      opacity: '0.9',
    },
  },
  documentStructure: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
    height: '100px',
    padding: '10px',
  },
  buttonDocument: {
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    backgroundColor: 'white',
    border: 'none',
    gap: 5,
    cursor: 'pointer',
  },
  documentName: {
    width: '100%',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    overflowWrap: 'break-word',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: '25px',
    width: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    [themeBreakpoints.down('sm')]: {
      borderTopRightRadius: '10px',
      borderTopLeftRadius: '10px',
      width: 'auto',
      padding: '25px',
    },
  },
  textFieldBox: {
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
    [themeBreakpoints.down('sm')]: {
      right: 10,
      top: 15,
    },
  },
  accordionBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    alignItems: 'start',
  },
  accordionHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5,
  },
  accordionSummary: {
    paddingLeft: '50px',
    paddingTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: 10,
  },
  itemClause: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
}));

export default styles;
