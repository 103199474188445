/* eslint-disable no-unused-vars */
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

import { COLORS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  container: {
    backgroundColor: COLORS.grayBackground,
    overflow: 'auto',
    height: 'calc(100vh - 75.33px)',
    '&::-webkit-scrollbar': {
      height: '3px',
      width: '3px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '5px',
      backgroundColor: COLORS.grayBackground,
    },
    '&::-webkit-scrollbar-track:hover': {
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar-track:active': {
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: COLORS.scrollbar,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: COLORS.scrollbar,
    },
    '&::-webkit-scrollbar-thumb:active': {
      backgroundColor: COLORS.scrollbar,
    },
    [themeBreakpoints.up('md')]: {
      height: '100vh',
    },
  },
  mainWrapper: {
    padding: '25px 25px 0',
    boxSizing: 'border-box',
    color: COLORS.gray,
    [themeBreakpoints.up('md')]: {
      padding: '40px 25px 0',
    },
  },
  pageTitle: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    margin: 0,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 28px 0',
  },
  backIcon: {
    backgroundColor: 'transparent',
    border: 0,
    padding: 0,
    height: '20px',
    '& svg': {
      width: '20px',
      height: '20px',
    },

    '&:hover': {
      cursor: 'pointer',
    },
  },
  detailSectionContainer: {
    position: 'relative',
    height: 'auto',
    borderRadius: '10px',
    backgroundColor: COLORS.white,
    margin: '0 0 28px 0',
    color: COLORS.black,

    '&.booking': {
      padding: '20px',
    },

    '&.table': {
      padding: '12px 0px',
    },
  },
  detailSectionSkeleton: {
    position: 'relative',
    borderRadius: '10px',
    margin: '0 0 28px 0',
    height: '220px !important',
    transform: 'none !important',
    width: '60%',

    '&.table': {
      padding: '12px 0px',
    },
  },

  bookingSectionTitle: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    margin: '0 0 15px 0',
  },
  statusPill: {
    position: 'absolute',
    top: 20,
    right: 20,
    borderRadius: '50px',
    padding: '5px 15px',
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '15px',
    '&.red': {
      backgroundColor: COLORS.redTag,
      color: COLORS.white,
    },
    '&.green': {
      backgroundColor: COLORS.greenTag,
      color: COLORS.white,
    },
    '&.yellow': {
      backgroundColor: COLORS.yellowTag,
    },
    '&.gray': {
      backgroundColor: COLORS.grayTag,
    },
  },
  bookingDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '10px',
  },
  bookingDataRow: {
    width: '100%',
  },
  bookingDataTitle: {
    fontFamily: 'Poppins',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '18px',
    margin: '0',
  },
  bookingDataDetail: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px',
    margin: '0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '0 15px 0 0',
  },
  bookingDataButtonContainer: {
    display: 'flex',
    gap: '15px',
    margin: '15px 0 8px',
    flexWrap: 'wrap',
  },
  filledButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: COLORS.white,
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    padding: '10px',
    backgroundColor: COLORS.orange,
    border: 0,
    borderRadius: '10px',
    minWidth: '100px',

    '& svg': {
      width: '20px',
      height: '20px',
      paddingRight: '5px',
    },

    '&:hover': {
      cursor: 'pointer',
    },
  },
  whiteButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: COLORS.orange,
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    padding: '10px',
    backgroundColor: COLORS.white,
    border: `1px solid ${COLORS.orange}`,
    borderRadius: '10px',
    minWidth: '100px',

    '& svg': {
      width: '20px',
      height: '20px',
      paddingRight: '5px',
    },

    '&:hover': {
      cursor: 'pointer',
    },
  },
  pageSubtitle: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    margin: '0 0 12px 0',
  },
  tableRowPadding: {
    padding: '0 20px',
  },
  tableTitleContainer: {
    width: '100%',
    paddingBottom: '8px',
    borderBottom: `1px solid #40424242`,
  },
  tableTitle: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    margin: 0,
  },
  tableRowsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 20px 0',
    gap: '24px',
  },
  tableDetailText: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    margin: 0,

    '&.bold': {
      fontWeight: 600,
    },
  },
  paymentSection: {
    display: 'flex',
    gap: '24px',
    flexDirection: 'column',
    '& .first': {
      width: '60%',
      height: 'fit-content',
      marginBottom: '10px',
      [themeBreakpoints.up('md')]: {
        marginBottom: '28px',
      },
    },
    '& .second': {
      width: 'calc(80% - 24px)',
      height: 'fit-content',
      maxWidth: '270px',
    },
    [themeBreakpoints.up('md')]: {
      flexDirection: 'row',
      '& .second': {
        width: 'calc(40% - 24px)',
        height: 'fit-content',
      },
    },
  },
  historyTitleSkeleton: {
    transform: 'none !important',
    width: '120px',
    height: '27px',
    margin: '0 0 12px 0 !important',
  },
  historyContentSkeleton: {
    transform: 'none !important',
    width: '100%',
    height: '140px !important',
    borderRadius: '10px',
    margin: '0 0 28px 0',
  },
  svg: {
    color: COLORS.gray,
  },
}));

export default styles;
