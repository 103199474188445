import { useMutation } from '@apollo/client';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Alert,
  CircularProgress,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from '@mui/material';
import clsx from 'clsx';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import CustomPrimaryButton from '../../components/CustomButton';
import { PATHS, URL_IMAGES } from '../../constants';
import { useAuth } from '../../helpers/AuthenticationProvider';
import { USER_LOGIN } from '../../queries/authentication';
import fontStyles from '../../styles/fontStyles';
import styles from './styles';

const Login = () => {
  const classes = styles();
  const fontClasses = fontStyles();
  const { t } = useTranslation();
  const { setToken, setUserId } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(t('validationsForm.validEmail'))
      .required(t('validationsForm.required')),
    password: yup.string().required(t('validationsForm.required')),
  });

  const [login, { data: loginResult, loading }] = useMutation(USER_LOGIN, {
    onError: () => {
      formik.setFieldError('form', t('validationsForm.invalidCredentials'));
    },
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      login({
        variables: {
          input: {
            identifier: values.email,
            password: values.password,
            provider: 'local',
          },
        },
      });
    },
  });

  useEffect(() => {
    if (loginResult && loginResult?.login?.jwt) {
      const loginJWT = loginResult.login.jwt;
      const loginIdResult = loginResult.login.user.id;
      setToken(loginJWT);
      setUserId(loginIdResult);
    }
  }, [loginResult]);

  return (
    <div className={classes.mainWrapper}>
      <div className={classes.loginBox}>
        <div className={classes.logoTitle}>
          <Link to='/' className={classes.logoBox}>
            <img
              className={classes.logo}
              src={URL_IMAGES.URL_LOGO_CARIBBEAN_ADVENTURES}
              alt='logo'
            />
          </Link>
          <h5 className={clsx(fontClasses.h5, classes.title)}>{t('loginPage.title')}</h5>
        </div>
        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
          <div className={classes.formBox}>
            <div className={classes.inputTextBox}>
              <p className={clsx(fontClasses.p, classes.titleInput)}>{t('loginPage.email')}</p>
              <TextField
                fullWidth
                variant='outlined'
                id='email'
                name='email'
                type='email'
                value={formik.values.email}
                onChange={formik.handleChange}
              />
            </div>
            <div className={classes.inputTextBox}>
              <p className={clsx(fontClasses.p, classes.titleInput)}>{t('loginPage.password')}</p>
              <OutlinedInput
                fullWidth
                id='outlined-adornment-password'
                type={showPassword ? 'text' : 'password'}
                name='password'
                value={formik.values.password}
                onChange={formik.handleChange}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='start'
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
            {(formik.errors?.form || formik.errors?.email || formik.errors?.password) &&
              formik.submitCount > 0 && (
                <Alert severity='error'>
                  {formik.errors.email ||
                    formik.errors.password ||
                    formik.errors?.form ||
                    t('validationsForm.serverError')}
                </Alert>
              )}
            {loading && (
              <div className={classes.spinner}>
                <CircularProgress color='primary' />
              </div>
            )}
            <div className={classes.buttonBox}>
              <CustomPrimaryButton
                fullWidth
                tertiary
                label={t('loginPage.submitButton')}
                disabled={loading}
                submit
              />
              <Link to={PATHS.recovery} className={classes.linkForget} underline='none'>
                <p className={clsx(fontClasses.p, classes.forgetLink)}>
                  {t('loginPage.forgetPasswordLink')}
                </p>
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
