import { gql } from '@apollo/client';

export const UPDATE_CONFIGURATION_DATA = gql`
  mutation Mutation($data: ClientParamInput!) {
    updateClientParam(data: $data) {
      data {
        id
        attributes {
          phone
          name
          email
          about_us
          location_address
          frequently_asked
          cancelation_policy
          privacy_policy {
            data {
              id
              attributes {
                url
                name
              }
            }
          }
          reimbursements {
            data {
              id
              attributes {
                url
                name
              }
            }
          }
          terms_of_service {
            data {
              id
              attributes {
                url
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const QUERY_CONFIGURATION_DATA = gql`
  query Query {
    clientParam {
      data {
        id
        attributes {
          about_us
          cancelation_policy
          email
          frequently_asked
          location_address
          name
          phone
          privacy_policy {
            data {
              id
              attributes {
                url
                name
              }
            }
          }
          reimbursements {
            data {
              id
              attributes {
                url
                name
              }
            }
          }
          terms_of_service {
            data {
              id
              attributes {
                url
                name
              }
            }
          }
        }
      }
    }
  }
`;
