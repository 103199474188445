import { SnackbarProvider, useSnackbar } from 'notistack';
import React, { createContext, useContext } from 'react';

// Create a context for the alert system
const AlertContext = createContext();

export const useAlert = () => {
  return useContext(AlertContext);
};

export const AlertProvider = ({ children }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // Show alert using notistack
  const showAlert = (message, severity = 'info') => {
    enqueueSnackbar(message, {
      variant: severity,
      autoHideDuration: 6000,
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      style: {
        fontFamily: 'Poppins', // Custom font
        paddingLeft: '16px',
      },
      action: (key) => (
        <button
          onClick={() => {
            closeSnackbar(key);
          }}
          style={{
            background: 'transparent',
            border: 'none',
            color: 'white',
            cursor: 'pointer',
          }}
        >
          ✖
        </button>
      ),
    });
  };

  return <AlertContext.Provider value={showAlert}>{children}</AlertContext.Provider>;
};

// Wrap your app in the SnackbarProvider
export const AppWrapper = ({ children }) => (
  <SnackbarProvider maxSnack={5}>
    <AlertProvider>{children}</AlertProvider>
  </SnackbarProvider>
);
