import axios from 'axios';

export const uploadFile = async (file) => {
  // Create a FormData object
  const formData = new FormData();
  formData.append('files', file); // 'files' is the expected key in Strapi for file uploads

  const response = await axios.post(`${process.env.REACT_APP_STRAPI_API}/upload`, formData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('cad-jwt-token')}`,
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};
