import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { makeStyles } from '@material-ui/styles';

// import { COLORS } from '../../constants';
import breakpoints from '../../theme/breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

const styles = makeStyles((theme) => ({
  modalContent: {
    padding: '25px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    [themeBreakpoints.down('sm')]: {
      borderTopRightRadius: '10px',
      borderTopLeftRadius: '10px',
    },
  },
  modalButtonsContent: {
    display: 'flex',
    gap: 10,
    justifyContent: 'end',
  },
}));

export default styles;
